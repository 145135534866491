import './App.css';
import { useState } from 'react'
import React, { useRef } from 'react';
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { PhotoIcon, UserCircleIcon, InformationCircleIcon } from '@heroicons/react/24/solid'


const navigation = [
  // { name: 'Projects', href: '#' },
  // { name: 'Resources', href: '#' },
  // { name: 'About Asha', href: '#' },
]
const stats = [
  // { label: 'Transactions every 24 hours', value: '44 million' },
  // { label: 'Assets under holding', value: '$119 trillion' },
  // { label: 'New users annually', value: '46,000' },
]
const values = [
  {
    name: 'Individual technological solution:',
    description:
      'Every person, their needs and circumstances are unique therefore the technology should be suited to their specific needs.',
  },
  {
    name: 'Co-design:',
    description:
      'Every technological solution is built for the user and with the user.',
  },
  {
    name: 'Do it now:',
    description:
      'We believe that there is no problem too small or too insignificant, if there is a need, we will work toward meeting it.',
  },
  {
    name: 'Low cost:',
    description:
      'We will do everything we physically can to ensure no one with a disability is left behind because they cannot afford a technology solution.',
  },
  {
    name: 'Joyful:',
    description:
      'We build because it brings others joy and because we love building.',
  },
  {
    name: 'Creative:',
    description:
      'Think creatively to find solutions that meet the need, it does not have to be innovative, it just needs bring a smile to the users.',
  },
]
const team = [
  {
    name: 'Dr Sam John',
    role: 'CEO',
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/asha-tech.appspot.com/o/1684394219253.jpeg?alt=media&token=e1162edc-d013-40f6-9922-f2f30c98d998&_gl=1*dniowc*_ga*MTgzMDA1NTY0MS4xNjk4MzA4NjE2*_ga_CW55HF8NVT*MTY5ODM2OTk3Ni40LjEuMTY5ODM3MDI1Mi4zNS4wLjA.',
  },
  {
    name: 'Dr Sam Donegan',
    role: 'COO',
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/asha-tech.appspot.com/o/1619076059603.jpeg?alt=media&token=c57ebfa2-eb2c-44f7-8ab7-c2c31d000814&_gl=1*4itbmm*_ga*MTgzMDA1NTY0MS4xNjk4MzA4NjE2*_ga_CW55HF8NVT*MTY5ODM2OTk3Ni40LjEuMTY5ODM3MDMzOS42MC4wLjA.',
  },
  // More people...
]
const blogPosts = [
  {
    id: 3,
    title: 'Kids living with a disability are gamers too',
    href: 'https://pursuit.unimelb.edu.au/articles/kids-with-disability-are-gamers-too',
    description:
      'Working with an eight-year-old boy with cerebral palsy, researchers have co-designed a video game controller to provide more opportunities for fun and social connection',
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/asha-tech.appspot.com/o/8d0723b8163b6436320c4b5fd42b6358ea19e11aeaeb7261b855bcbee4a9.jpeg?alt=media&token=73f3106a-803a-42eb-b3a7-3c6cb955f992&_gl=1*ou2m40*_ga*MTgzMDA1NTY0MS4xNjk4MzA4NjE2*_ga_CW55HF8NVT*MTY5ODYzNjI0Ni42LjEuMTY5ODYzNjM2NC4xMS4wLjA.',
    date: 'Oct 27, 2023',
    datetime: '2023-10-27',
    author: {
      name: 'Dr Sam John',
      imageUrl:
        'https://firebasestorage.googleapis.com/v0/b/asha-tech.appspot.com/o/1684394219253.jpeg?alt=media&token=e1162edc-d013-40f6-9922-f2f30c98d998&_gl=1*1sqrzba*_ga*MTgzMDA1NTY0MS4xNjk4MzA4NjE2*_ga_CW55HF8NVT*MTY5ODYzNjI0Ni42LjEuMTY5ODYzNjI1NS41MS4wLjA.',
    },
  },
  {
    id: 2,
    title: 'Asha: Dr Sam John interview with ABC Radio',
    href: 'https://app.mediaportal.com/isentia/#/playnow/v2?id=R00103048185&channel=ABC%20Radio%20Melbourne&location=null&date=2023-10-30T18:24:15&program=Drive&item_id=1015812497&prospect_id=2628516776&is_video=false&keywords=University%20of%20Melbourne&expiry=1730312655&signature=ASZVdqbILlR9bHIKloH6tIInF8Mo17uaqqACnwLeZxCpC7Jtv-BHsinUDN15YDCVPyiJy9Rk8arxryqSwN2H0-756ulfqECnJ4hPhayv3kQOpTzgTffq1b5DY9WTuQDPobZwJlRq~GZlypGAdh7dwzj-Tots9eHqvu7crqqnn6nbR4z2lpsUW1IpsOHfuJcgol-jQnJOt7bmlFc7~2ufD4Y8KJgsP~B4xZIKHnMRg4u-9Xs8WAqiEhkzHUuQj2pdmyxrdJWwheg6I5qTZtn5yFC29flsvajZrPxtYQHdUq7HW5Hf9QRw-K0tkEp6faVQusNKAs33ns7kibqVBTHuuw__',
    description:
      'If you like video games and 75% of us play socially, imagine what it would be like if you physically couldn\'t play them if you didn\'t have the fine motor function necessary. Well that was the case for seven year old Jerome, who has cerebral palsy, but he\'s just played his first video game with a customised video game controller. It was put together by a team of University of Melbourne biomedical engineering master\'s students. Dr Sam John is a senior lecturer in neural engineering at the University Dr John, welcome to Drive.',
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/asha-tech.appspot.com/o/b7ccfc397636976460edc34b499ab0539698d3bece72ad88b1f25cedd84a.webp?alt=media&token=facb00f9-83b1-42ad-9750-38398dc181f8&_gl=1*31bz6c*_ga*MTgzMDA1NTY0MS4xNjk4MzA4NjE2*_ga_CW55HF8NVT*MTY5ODgwMzM0NC4xMC4xLjE2OTg4MDM4NDMuMTguMC4w',
    date: 'Oct 29, 2023',
    datetime: '2023-10-29',
    author: {
      name: 'Dr Sam John',
      imageUrl:
        'https://firebasestorage.googleapis.com/v0/b/asha-tech.appspot.com/o/1684394219253.jpeg?alt=media&token=e1162edc-d013-40f6-9922-f2f30c98d998&_gl=1*1sqrzba*_ga*MTgzMDA1NTY0MS4xNjk4MzA4NjE2*_ga_CW55HF8NVT*MTY5ODYzNjI0Ni42LjEuMTY5ODYzNjI1NS41MS4wLjA.',
    },
  },
  {
    id: 1,
    title: 'Asha: Dr Sam John interview with 3RRR',
    href: 'https://www.rrr.org.au/shared/broadcast-episode/26942/855000/1810000',
    description:
      'Byte Into IT, 1 November 2023. If you like video games and 75% of us play socially, imagine what it would be like if you physically couldn\'t play them if you didn\'t have the fine motor function necessary. Well that was the case for seven year old Jerome, who has cerebral palsy, but he\'s just played his first video game with a customised video game controller. It was put together by a team of University of Melbourne biomedical engineering master\'s students.',
    imageUrl:
      'https://firebasestorage.googleapis.com/v0/b/asha-tech.appspot.com/o/IMG_7973-2.jpg?alt=media&token=09e8cdcc-b2f6-46da-918a-78dac48ef576&_gl=1*1yenfar*_ga*MTgzMDA1NTY0MS4xNjk4MzA4NjE2*_ga_CW55HF8NVT*MTY5OTMyOTc1MC4xNC4xLjE2OTkzMzI5MjEuNDIuMC4w',
    date: 'Nov 1, 2023',
    datetime: '2023-11-1',
    author: {
      name: 'Dr Sam John',
      imageUrl:
        'https://firebasestorage.googleapis.com/v0/b/asha-tech.appspot.com/o/1684394219253.jpeg?alt=media&token=e1162edc-d013-40f6-9922-f2f30c98d998&_gl=1*1sqrzba*_ga*MTgzMDA1NTY0MS4xNjk4MzA4NjE2*_ga_CW55HF8NVT*MTY5ODYzNjI0Ni42LjEuMTY5ODYzNjI1NS41MS4wLjA.',
    },
  },

  // More posts...
]
const footerNavigation = {
  main: [
    // { name: 'Blog', href: '#' },
    // { name: 'Jobs', href: '#' },
    // { name: 'Press', href: '#' },
    // { name: 'Accessibility', href: '#' },
    // { name: 'Partners', href: '#' },
  ],
  social: [
    // {
    //   name: 'Facebook',
    //   href: '#',
    //   icon: (props) => (
    //     <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
    //       <path
    //         fillRule="evenodd"
    //         d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
    //         clipRule="evenodd"
    //       />
    //     </svg>
    //   ),
    // },
    // {
    //   name: 'Instagram',
    //   href: '#',
    //   icon: (props) => (
    //     <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
    //       <path
    //         fillRule="evenodd"
    //         d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
    //         clipRule="evenodd"
    //       />
    //     </svg>
    //   ),
    // },
    // {
    //   name: 'Twitter',
    //   href: '#',
    //   icon: (props) => (
    //     <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
    //       <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
    //     </svg>
    //   ),
    // },
    // {
    //   name: 'GitHub',
    //   href: '#',
    //   icon: (props) => (
    //     <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
    //       <path
    //         fillRule="evenodd"
    //         d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
    //         clipRule="evenodd"
    //       />
    //     </svg>
    //   ),
    // },
    // {
    //   name: 'YouTube',
    //   href: '#',
    //   icon: (props) => (
    //     <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
    //       <path
    //         fillRule="evenodd"
    //         d="M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z"
    //         clipRule="evenodd"
    //       />
    //     </svg>
    //   ),
    // },
  ],
}

export default function LandingPage() {
  // const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const signupRef = useRef(null);
  return (
    <div className="bg-white">
      {/* Header */}
      <header className="absolute inset-x-0 top-0 z-50">
        <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
          <div className="flex lg:flex-1">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Asha</span>
              <img
                className="h-8 w-auto"
                src="https://firebasestorage.googleapis.com/v0/b/asha-tech.appspot.com/o/asha-logo-min.png?alt=media&token=eae87eac-c772-4227-859c-3acc23439176&_gl=1*1sljnej*_ga*MTgzMDA1NTY0MS4xNjk4MzA4NjE2*_ga_CW55HF8NVT*MTY5ODM2OTk3Ni40LjEuMTY5ODM3MDY2My40NS4wLjA."
                alt=""
              />
            </a>
          </div>
          <div className="flex lg:hidden">
            {/* <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button> */}
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => (
              <a key={item.name} href={item.href} className="text-sm font-semibold leading-6 text-gray-900">
                {item.name}
              </a>
            ))}
          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            {/* <a href="#" className="text-sm font-semibold leading-6 text-gray-900">
              Log in <span aria-hidden="true">&rarr;</span>
            </a> */}
          </div>
        </nav>
        {/* <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
          <div className="fixed inset-0 z-50" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <a href="#" className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                <img
                  className="h-8 w-auto"
                  src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                  alt=""
                />
              </a>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                <div className="py-6">
                  <a
                    href="#"
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Log in
                  </a>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog> */}
      </header>

      <main className="isolate">
        {/* Hero section */}
        <div className="relative isolate -z-10">
          <svg
            className="absolute inset-x-0 top-0 -z-10 h-[64rem] w-full stroke-gray-200 [mask-image:radial-gradient(32rem_32rem_at_center,white,transparent)]"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84"
                width={200}
                height={200}
                x="50%"
                y={-1}
                patternUnits="userSpaceOnUse"
              >
                <path d="M.5 200V.5H200" fill="none" />
              </pattern>
            </defs>
            <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
              <path
                d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
                strokeWidth={0}
              />
            </svg>
            <rect width="100%" height="100%" strokeWidth={0} fill="url(#1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84)" />
          </svg>
          <div
            className="absolute left-1/2 right-0 top-0 -z-10 -ml-24 transform-gpu overflow-hidden blur-3xl lg:ml-24 xl:ml-48"
            aria-hidden="true"
          >
            <div
              className="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
              style={{
                clipPath:
                  'polygon(63.1% 29.5%, 100% 17.1%, 76.6% 3%, 48.4% 0%, 44.6% 4.7%, 54.5% 25.3%, 59.8% 49%, 55.2% 57.8%, 44.4% 57.2%, 27.8% 47.9%, 35.1% 81.5%, 0% 97.7%, 39.2% 100%, 35.2% 81.4%, 97.2% 52.8%, 63.1% 29.5%)',
              }}
            />
          </div>
          <div className="overflow-hidden">
            <div className="mx-auto max-w-7xl px-6 pb-32 pt-36 sm:pt-60 lg:px-8 lg:pt-32">
              <div className="mx-auto max-w-2xl gap-x-14 lg:mx-0 lg:flex lg:max-w-none lg:items-center">
                <div className="w-full max-w-xl lg:shrink-0 xl:max-w-2xl">
                  <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                    Asha. We create custom devices for people living with a disability.
                  </h1>
                  <p className="relative mt-6 text-lg leading-8 text-gray-600 sm:max-w-md lg:max-w-none">
                    Asha brings together people living with a disability, engineering students, designers, university staff and supporters, to <strong>understand</strong> a person's accessibility need, and create a custom solution to address that need.
                  </p>
                  <button
                    type="button"
                    className="rounded-md bg-indigo-600 mt-3.5 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={() => signupRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })}
                  >
                    Sign up
                  </button>
                </div>
                <div className="mt-14 flex justify-end gap-8 sm:-mt-44 sm:justify-start sm:pl-20 lg:mt-0 lg:pl-0">
                  <div className="ml-auto w-44 flex-none space-y-8 pt-32 sm:ml-0 sm:pt-80 lg:order-last lg:pt-36 xl:order-none xl:pt-80">
                    <div className="relative">
                      <img
                        src="https://firebasestorage.googleapis.com/v0/b/asha-tech.appspot.com/o/IMG_7973-2.jpg?alt=media&token=09e8cdcc-b2f6-46da-918a-78dac48ef576&_gl=1*12w28fs*_ga*MTgzMDA1NTY0MS4xNjk4MzA4NjE2*_ga_CW55HF8NVT*MTY5ODM2OTk3Ni40LjEuMTY5ODM3MDc3Mi45LjAuMA.."
                        alt=""
                        className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                      />
                      <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                  </div>
                  <div className="mr-auto w-44 flex-none space-y-8 sm:mr-0 sm:pt-52 lg:pt-36">
                    <div className="relative">
                      <img
                        src="https://images.unsplash.com/photo-1485217988980-11786ced9454?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&h=528&q=80"
                        alt=""
                        className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                      />
                      <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                    <div className="relative">
                      <img
                        src="https://images.unsplash.com/photo-1559136555-9303baea8ebd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=focalpoint&fp-x=.4&w=396&h=528&q=80"
                        alt=""
                        className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                      />
                      <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                  </div>
                  <div className="w-44 flex-none space-y-8 pt-32 sm:pt-0">
                    <div className="relative">
                      <img
                        src="https://images.unsplash.com/photo-1670272504528-790c24957dda?ixlib=rb-4.0.3&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=left&w=400&h=528&q=80"
                        alt=""
                        className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                      />
                      <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                    <div className="relative">
                      <img
                        src="https://firebasestorage.googleapis.com/v0/b/asha-tech.appspot.com/o/IMG_1443-2.jpg?alt=media&token=ed031014-6a57-475f-839f-708d13fa6fbf&_gl=1*1drcohh*_ga*MTgzMDA1NTY0MS4xNjk4MzA4NjE2*_ga_CW55HF8NVT*MTY5ODM2OTk3Ni40LjEuMTY5ODM3MDcyNS41Ni4wLjA."
                        alt=""
                        className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                      />
                      <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Testimonial */}
        <section className="isolate overflow-hidden bg-transparent px-6 lg:px-8">
          <div className="relative mx-auto max-w-2xl py-24 sm:py-32 lg:max-w-4xl">
            <div className="absolute left-1/2 top-0 -z-10 h-[50rem] w-[90rem] -translate-x-1/2 bg-[radial-gradient(50%_100%_at_top,theme(colors.indigo.100),white)] opacity-20 lg:left-36" />
            <div className="absolute inset-y-0 right-1/2 -z-10 mr-12 w-[150vw] origin-bottom-left skew-x-[-30deg] bg-transparent shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 sm:mr-20 md:mr-0 lg:right-full lg:-mr-36 lg:origin-center" />
            <figure className="grid grid-cols-1 items-center gap-x-6 gap-y-8 lg:gap-x-10">
              <div className="relative col-span-2 lg:col-start-1 lg:row-start-2">
                <svg
                  viewBox="0 0 162 128"
                  fill="none"
                  aria-hidden="true"
                  className="absolute -top-12 left-0 -z-10 h-32 stroke-gray-900/10"
                >
                  <path
                    id="b56e9dab-6ccb-4d32-ad02-6b4bb5d9bbeb"
                    d="M65.5697 118.507L65.8918 118.89C68.9503 116.314 71.367 113.253 73.1386 109.71C74.9162 106.155 75.8027 102.28 75.8027 98.0919C75.8027 94.237 75.16 90.6155 73.8708 87.2314C72.5851 83.8565 70.8137 80.9533 68.553 78.5292C66.4529 76.1079 63.9476 74.2482 61.0407 72.9536C58.2795 71.4949 55.276 70.767 52.0386 70.767C48.9935 70.767 46.4686 71.1668 44.4872 71.9924L44.4799 71.9955L44.4726 71.9988C42.7101 72.7999 41.1035 73.6831 39.6544 74.6492C38.2407 75.5916 36.8279 76.455 35.4159 77.2394L35.4047 77.2457L35.3938 77.2525C34.2318 77.9787 32.6713 78.3634 30.6736 78.3634C29.0405 78.3634 27.5131 77.2868 26.1274 74.8257C24.7483 72.2185 24.0519 69.2166 24.0519 65.8071C24.0519 60.0311 25.3782 54.4081 28.0373 48.9335C30.703 43.4454 34.3114 38.345 38.8667 33.6325C43.5812 28.761 49.0045 24.5159 55.1389 20.8979C60.1667 18.0071 65.4966 15.6179 71.1291 13.7305C73.8626 12.8145 75.8027 10.2968 75.8027 7.38572C75.8027 3.6497 72.6341 0.62247 68.8814 1.1527C61.1635 2.2432 53.7398 4.41426 46.6119 7.66522C37.5369 11.6459 29.5729 17.0612 22.7236 23.9105C16.0322 30.6019 10.618 38.4859 6.47981 47.558L6.47976 47.558L6.47682 47.5647C2.4901 56.6544 0.5 66.6148 0.5 77.4391C0.5 84.2996 1.61702 90.7679 3.85425 96.8404L3.8558 96.8445C6.08991 102.749 9.12394 108.02 12.959 112.654L12.959 112.654L12.9646 112.661C16.8027 117.138 21.2829 120.739 26.4034 123.459L26.4033 123.459L26.4144 123.465C31.5505 126.033 37.0873 127.316 43.0178 127.316C47.5035 127.316 51.6783 126.595 55.5376 125.148L55.5376 125.148L55.5477 125.144C59.5516 123.542 63.0052 121.456 65.9019 118.881L65.5697 118.507Z"
                  />
                  <use href="#b56e9dab-6ccb-4d32-ad02-6b4bb5d9bbeb" x={86} />
                </svg>
                <blockquote className="text-xl font-semibold leading-8 text-gray-900 sm:text-2xl sm:leading-9">
                  <p>
                    "My brother has been diagnosed with ALS. In the last six months has lost function of both arms.
                  </p>
                  <p>
                    He loves PlayStation.
                  </p>
                  <p>
                    <strong>Please help me.</strong> He is now confined to a lift chair. Depressed and missing PlayStation.
                  </p>
                  <p>
                    Can you help me make him smile a little?
                  </p>
                  <p>
                    Thank you!"
                  </p>
                </blockquote>
              </div>
              <div className="col-end-1 w-16 lg:row-span-4 lg:w-72">
                <img
                  className="rounded-xl bg-indigo-50 lg:rounded-3xl"
                  src="https://firebasestorage.googleapis.com/v0/b/asha-tech.appspot.com/o/IMG-4326.jpg?alt=media&token=5a23da1d-85a5-4a34-8d5a-78f43931ba22&_gl=1*917j3e*_ga*MTgzMDA1NTY0MS4xNjk4MzA4NjE2*_ga_CW55HF8NVT*MTY5ODM2OTk3Ni40LjEuMTY5ODM3MTAzOC4yOC4wLjA."
                  alt=""
                />
              </div>
              <figcaption className="text-base lg:col-start-1 lg:row-start-3">
                <div className="font-semibold text-gray-900">Suzanne</div>
                <div className="mt-1 text-gray-500">Sister</div>
              </figcaption>
            </figure>
          </div>
        </section>


        {/* Content section */}
        <div className="mx-auto -mt-12 max-w-7xl px-6 sm:mt-0 lg:px-8 xl:-mt-8">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Our mission</h2>
            <div className="mt-6 flex flex-col gap-x-8 gap-y-20 lg:flex-row">
              <div className="lg:w-full lg:max-w-2xl lg:flex-auto">
                <p className="text-xl leading-8 text-gray-600">
                  We exist to help people with complex disabilities and their families live better lives by providing individualised technological solutions.
                </p>
                {/* <div className="mt-10 max-w-xl text-base leading-7 text-gray-700">
                  <p>
                    Faucibus commodo massa rhoncus, volutpat. Dignissim sed eget risus enim. Mattis mauris semper sed
                    amet vitae sed turpis id. Id dolor praesent donec est. Odio penatibus risus viverra tellus varius
                    sit neque erat velit. Faucibus commodo massa rhoncus, volutpat. Dignissim sed eget risus enim.
                    Mattis mauris semper sed amet vitae sed turpis id.
                  </p>
                  <p className="mt-10">
                    Et vitae blandit facilisi magna lacus commodo. Vitae sapien duis odio id et. Id blandit molestie
                    auctor fermentum dignissim. Lacus diam tincidunt ac cursus in vel. Mauris varius vulputate et
                    ultrices hac adipiscing egestas. Iaculis convallis ac tempor et ut. Ac lorem vel integer orci.
                  </p>
                </div> */}
              </div>
              {/* <div className="lg:flex lg:flex-auto lg:justify-center">
                <dl className="w-64 space-y-8 xl:w-80">
                  {stats.map((stat) => (
                    <div key={stat.label} className="flex flex-col-reverse gap-y-4">
                      <dt className="text-base leading-7 text-gray-600">{stat.label}</dt>
                      <dd className="text-5xl font-semibold tracking-tight text-gray-900">{stat.value}</dd>
                    </div>
                  ))}
                </dl>
              </div> */}
            </div>
          </div>
        </div>

        {/* Values section */}
        <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-40 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Our values</h2>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              {/* Lorem ipsum dolor sit amet consect adipisicing elit. Possimus magnam voluptatum cupiditate veritatis in
              accusamus quisquam. */}
            </p>
          </div>
          <dl className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 text-base leading-7 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            {values.map((value) => (
              <div key={value.name}>
                <dt className="font-semibold text-gray-900">{value.name}</dt>
                <dd className="mt-1 text-gray-600">{value.description}</dd>
              </div>
            ))}
          </dl>
        </div>

        {/* Video section */}
        <div className="flex justify-center sm:mt-10 xl:mx-auto xl:max-w-7xl xl:px-8">
          {/* <img
            src="https://images.unsplash.com/photo-1529156069898-49953e39b3ac?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2832&q=80"
            alt=""
            className="aspect-[5/2] w-full object-cover xl:rounded-3xl"
          /> */}
          <iframe width="560" height="315" src="https://www.youtube.com/embed/C2fktd3b6X8?si=VZvsnX1RLv32D5xa" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>

        {/* Logo cloud */}
        <div className="relative isolate -z-10 sm:mt-10">
          <div className="absolute inset-x-0 top-1/2 -z-10 flex -translate-y-1/2 justify-center overflow-hidden [mask-image:radial-gradient(50%_45%_at_50%_55%,white,transparent)]">
            <svg className="h-[40rem] w-[80rem] flex-none stroke-gray-200" aria-hidden="true">
              <defs>
                <pattern
                  id="e9033f3e-f665-41a6-84ef-756f6778e6fe"
                  width={200}
                  height={200}
                  x="50%"
                  y="50%"
                  patternUnits="userSpaceOnUse"
                  patternTransform="translate(-100 0)"
                >
                  <path d="M.5 200V.5H200" fill="none" />
                </pattern>
              </defs>
              <svg x="50%" y="50%" className="overflow-visible fill-gray-50">
                <path d="M-300 0h201v201h-201Z M300 200h201v201h-201Z" strokeWidth={0} />
              </svg>
              <rect width="100%" height="100%" strokeWidth={0} fill="url(#e9033f3e-f665-41a6-84ef-756f6778e6fe)" />
            </svg>
          </div>
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">
              <div className="col-span-2 max-h-12 w-full object-contain sm:hidden lg:block lg:col-span-1 xl:block"></div>
              <img
                className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                src="https://firebasestorage.googleapis.com/v0/b/asha-tech.appspot.com/o/MelbourneUni-e1599556456324-2.png?alt=media&token=32e743df-0b47-41c8-a284-180df5a18f2e&_gl=1*18rph5n*_ga*MTUwMzAwNTA0MC4xNjk5MzU5MjEy*_ga_CW55HF8NVT*MTY5OTQ1MDEwMC42LjEuMTY5OTQ1MDc0OC40MC4wLjA."
                alt="University of Melbourne logo"
                width={158}
                height={48}
              />
              <img
                className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                src="https://firebasestorage.googleapis.com/v0/b/asha-tech.appspot.com/o/abc-news-logo-01-2.png?alt=media&token=94ce608e-cfda-4576-826a-ffe6dd2424d4&_gl=1*v8hr62*_ga*MTUwMzAwNTA0MC4xNjk5MzU5MjEy*_ga_CW55HF8NVT*MTY5OTQ1MDEwMC42LjEuMTY5OTQ1MDg3Ni41MS4wLjA."
                alt="ABC news logo"
                width={158}
                height={48}
              />
              <img
                className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                src="https://firebasestorage.googleapis.com/v0/b/asha-tech.appspot.com/o/6055.1464763234-2.png?alt=media&token=2e416eca-7448-4414-9837-9b00df72bf8f&_gl=1*1eihhbe*_ga*MTUwMzAwNTA0MC4xNjk5MzU5MjEy*_ga_CW55HF8NVT*MTY5OTQ1MDEwMC42LjEuMTY5OTQ1MDk1Ny41NS4wLjA."
                alt="3RRR logo"
                width={158}
                height={48}
              />
            </div>
          </div>
        </div>

        {/* Team section */}
        <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-48 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Our team</h2>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Our team is made up of a group of Biomedical Engineers from the University of Melbourne, Department of Biomedical Engineering
            </p>
            <div className="rounded-md bg-blue-50 p-4">
              <a href='https://biomedical.eng.unimelb.edu.au/john-neurobionics' target='blank'>
                <div className="flex">
                  <div className="flex-shrink-0">
                    <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3 flex-1 md:flex md:justify-between">
                    <p className="text-sm text-blue-700">To see more please visit the John Neurobionics Lab Webpage</p>
                    <p className="mt-3 text-sm md:ml-6 md:mt-0">
                      <a href="https://biomedical.eng.unimelb.edu.au/john-neurobionics" className="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600">
                        View webpage
                        <span aria-hidden="true"> &rarr;</span>
                      </a>
                    </p>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <ul
            role="list"
            className="mx-auto mt-20 grid max-w-2xl grid-cols-2 gap-x-8 gap-y-16 text-center sm:grid-cols-3 md:grid-cols-4 lg:mx-0 lg:max-w-none lg:grid-cols-5 xl:grid-cols-6"
          >
            {team.map((person) => (
              <li key={person.name}>
                <img className="mx-auto h-24 w-24 rounded-full" src={person.imageUrl} alt="" />
                <h3 className="mt-6 text-base font-semibold leading-7 tracking-tight text-gray-900">{person.name}</h3>
                <p className="text-sm leading-6 text-gray-600">{person.role}</p>
              </li>
            ))}
          </ul>
        </div>

        {/* Blog section */}
        <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-40 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">From the blog</h2>
            <p className="mt-2 text-lg leading-8 text-gray-600">
              Stay up to date with Asha's recent projects, devices and articles.
            </p>
          </div>
          <div className="mx-auto mt-16 grid max-w-2xl auto-rows-fr grid-cols-1 gap-8 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            {blogPosts.map((post) => (
              <article
                key={post.id}
                className="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl bg-gray-900 px-8 pb-8 pt-80 sm:pt-48 lg:pt-80"
              >
                <img src={post.imageUrl} alt="" className="absolute inset-0 -z-10 h-full w-full object-cover" />
                <div className="absolute inset-0 -z-10 bg-gradient-to-t from-gray-900 via-gray-900/40" />
                <div className="absolute inset-0 -z-10 rounded-2xl ring-1 ring-inset ring-gray-900/10" />

                <div className="flex flex-wrap items-center gap-y-1 overflow-hidden text-sm leading-6 text-gray-300">
                  <time dateTime={post.datetime} className="mr-8">
                    {post.date}
                  </time>
                  <div className="-ml-4 flex items-center gap-x-4">
                    <svg viewBox="0 0 2 2" className="-ml-0.5 h-0.5 w-0.5 flex-none fill-white/50">
                      <circle cx={1} cy={1} r={1} />
                    </svg>
                    <div className="flex gap-x-2.5">
                      <img src={post.author.imageUrl} alt="" className="h-6 w-6 flex-none rounded-full bg-white/10" />
                      {post.author.name}
                    </div>
                  </div>
                </div>
                <h3 className="mt-3 text-lg font-semibold leading-6 text-white">
                  <a href={post.href}>
                    <span className="absolute inset-0" />
                    {post.title}
                  </a>
                </h3>
              </article>
            ))}
          </div>
        </div>
      </main>

      {/* Sign Up */}
      <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-40 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Expressions of interest</h2>
          <p className="mt-2 text-lg leading-8 text-gray-600">            </p>
        </div>
        <div className="space-y-10 divide-y divide-gray-900/10">


          <div className="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3">
            <div className="px-4 sm:px-0">
              <h2 className="text-base font-semibold leading-7 text-gray-900">Submit an expression of interest</h2>
              <p className="mt-1 text-sm leading-6 text-gray-600">
                Whether you're a person living with a disability looking for a custom device, a loved one or just interested in Asha. Fill out your details here to send us a message and stay updated.
              </p>
            </div>

            <iframe ref={signupRef} id='signup' src="https://docs.google.com/forms/d/e/1FAIpQLSeaQAS_5rNEwSpFg7fdY27TUe6cYtwdJEwTs7xQnT-wkg8b3g/viewform?embedded=true" width="640" height="580" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>

            {/* <form className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
              <div className="px-4 py-6 sm:p-8">
                <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <div className="sm:col-span-3">
                    <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                      First name
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="first-name"
                        id="first-name"
                        autoComplete="given-name"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                      Last name
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="last-name"
                        id="last-name"
                        autoComplete="family-name"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-4">
                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                      Email address
                    </label>
                    <div className="mt-2">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-4">
                    <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
                      Country
                    </label>
                    <div className="mt-2">
                      <select
                        id="country"
                        name="country"
                        autoComplete="country-name"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                      >
                        <option>Australia</option>
                      </select>
                    </div>
                    <br></br>
                    <div className="rounded-md bg-blue-50 p-4">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3 flex-1 md:flex md:justify-between">
                          <p className="text-sm text-blue-700">Asha is only servicing Australia currently.</p>
                          <p className="mt-3 text-sm md:ml-6 md:mt-0">
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div className="sm:col-span-2">
                    <label htmlFor="postal-code" className="block text-sm font-medium leading-6 text-gray-900">
                      ZIP / Postal code
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="postal-code"
                        id="postal-code"
                        autoComplete="postal-code"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="col-span-full">
                    <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900">
                      Tell us about how we could work together
                    </label>
                    <div className="mt-2">
                      <textarea
                        id="about"
                        name="about"
                        rows={3}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        defaultValue={''}
                      />
                    </div>
                    <p className="mt-3 text-sm leading-6 text-gray-600">Write a few sentences about yourself, any accessibility needs you have or something you'd like to tell us.</p>
                  </div>
                  <img src='/ReCaptcha.png'></img>

                </div>
              </div>
              <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
                <button type="button" className="text-sm font-semibold leading-6 text-gray-900">
                  Cancel
                </button>
                <button
                  type="submit"
                  className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Submit
                </button>
              </div>
            </form> */}
          </div>
        </div>
      </div>




      {/* Footer */}
      <footer className="mx-auto mt-40 max-w-7xl overflow-hidden px-6 pb-20 sm:mt-64 sm:pb-24 lg:px-8">
        <nav className="-mb-6 columns-2 sm:flex sm:justify-center sm:space-x-12" aria-label="Footer">
          {footerNavigation.main.map((item) => (
            <div key={item.name} className="pb-6">
              <a href={item.href} className="text-sm leading-6 text-gray-600 hover:text-gray-900">
                {item.name}
              </a>
            </div>
          ))}
        </nav>
        <div className="mt-10 flex justify-center space-x-10">
          {footerNavigation.social.map((item) => (
            <a key={item.name} href={item.href} className="text-gray-400 hover:text-gray-500">
              <span className="sr-only">{item.name}</span>
              <item.icon className="h-6 w-6" aria-hidden="true" />
            </a>
          ))}
        </div>
        <p className="mt-10 text-center text-xs leading-5 text-gray-500">
          &copy; 2023 Asha Pty Ltd, All rights reserved.
        </p>
      </footer>
    </div>
  );
}

